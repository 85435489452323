import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';

import './mrect-native.scss';

export const MrectNative = memo(({ wrapperClass, style, verticalAlign, ...restProps }) => (
  <NativeAd
    wrapperClass={classnames('mrect mx-auto', wrapperClass)}
    adNameWired="mrect"
    adNameMobile="mmrect"
    nativeStyle="mediumrectangle"
    showLabel
    verticalAlign={verticalAlign}
    style={{ ...style, width: '300px' }}
    {...restProps}
  />
));

MrectNative.displayName = 'MrectNative';

MrectNative.propTypes = {
  wrapperClass: PropTypes.string,
  style: PropTypes.shape({}),
  verticalAlign: PropTypes.string,
};

MrectNative.defaultProps = {
  wrapperClass: '',
  style: undefined,
  verticalAlign: 'center',
};

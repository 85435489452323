import PropTypes from 'prop-types';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { PageModel } from './page';

const LinkWidgets = PropTypes.arrayOf(
  PropTypes.shape({
    categoryName: PropTypes.string,
    header: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        anchorText: PropTypes.string,
        contextualSentence: PropTypes.string,
      })
    ),
  })
);
export const LinkWidgetsEntities = {
  LinkWidgets,
};

async function resolveLinkWidgets(match, context, url) {
  let resolvedResult = {};
  try {
    resolvedResult = await withMetrics(EdmundsAPI, context).fetchJson(url);
  } catch (ex) {
    resolvedResult = {};
  }
  return resolvedResult.linkWidgets || resolvedResult.linkWidget || [];
}

/**
 * Path for link widgets that get data from /seo/v1/pagelinks
 * @returns {string}
 */
export const getPageLinksPath = () => 'pageLinks';

export const LinkWidgetsModel = createModelSegment('linkWidgets', [
  {
    path: 'pageLinks',
    async resolve(match, context) {
      const pageName = await context.resolveValue('page.name', PageModel);
      const legacyPageName = await context.resolveValue('legacy.pageName', PageModel);
      const pathname = (await context.resolveValue('location.pathname', PageModel)) || '';

      const url = `/seo/v1/pagelinks/${legacyPageName || pageName}?url=https://www.edmunds.com${pathname}`;

      return resolveLinkWidgets(match, context, url);
    },
  },
]);

import React from 'react';
import PropTypes from 'prop-types';
import { CmsEntities } from 'client/data/models/cms';
import { parseContentMetadata } from 'site-modules/shared/utils/parse-content-metadata';
import { TopMakesLink } from 'site-modules/shared/components/top-makes-link/top-makes-link';

export function TopMakesSection({ topMakes, className, linkClassName, linkTrackingId }) {
  const topMakesLinks = parseContentMetadata(topMakes.getAllMetadata(), ['link-url', 'title'], 20);

  return (
    <div className={className}>
      <div className="top-makes-section text-gray-darker row">
        <h2 className="heading-3 mb-1_5 col-12">{topMakes.title}</h2>
        {topMakesLinks.map(link => (
          <TopMakesLink topMake={link} className={linkClassName} key={link.linkUrl} dataTrackingId={linkTrackingId} />
        ))}
      </div>
    </div>
  );
}

TopMakesSection.propTypes = {
  topMakes: CmsEntities.Content.isRequired,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  linkTrackingId: PropTypes.string,
};

TopMakesSection.defaultProps = {
  className: null,
  linkClassName: null,
  linkTrackingId: null,
};

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { InventoryModel } from 'client/data/models/inventory';
import { CoreResearchEntities } from 'client/data/models/core-research';
import { generateSrpLinkNewMake, generateSrpLinkUsedMake } from 'site-modules/shared/utils/srp-link-constructor';
import { TYPE_FILTER } from 'site-modules/make/constants/make';
import { separatePubStates } from 'site-modules/make/utils/make';
import { getMakeIncentivesLink } from 'site-modules/shared/utils/incentives-link-constructor';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';

import { FiltersList } from './filters-list';
import { ModelsList } from './models-list';

function ModelsViewUI({ submodelsList, makeSlug, makeName, newInventoryCount, pubStates }) {
  const [activeFilter, setActiveFilter] = useState(TYPE_FILTER.ALL);

  if (!submodelsList || !submodelsList.length) {
    return null;
  }

  const { preProdList, newUsedList } = separatePubStates(submodelsList);

  const hasNewInvLink = newInventoryCount > 0;
  const hasUsedInvLink = pubStates && (pubStates.USED || pubStates.NEW_USED);

  return (
    <Fragment>
      {newUsedList && !!newUsedList.length && (
        <Fragment>
          <div data-tracking-parent="make-index-summary">
            {hasNewInvLink && (
              <Fragment>
                <ArrowLink
                  to={generateSrpLinkNewMake(makeSlug)}
                  className="mt-1_5"
                  data-tracking-id="view_search_result"
                >
                  See all new {makeName} vehicles for sale
                </ArrowLink>
                <ArrowLink to={`/${makeSlug}-lease-deals/`} className="mt-1_5" data-tracking-id="view_search_result">
                  Lease a new {makeName}
                </ArrowLink>
              </Fragment>
            )}
            <ArrowLink
              to={getMakeIncentivesLink(makeSlug)}
              className="mt-1_5"
              data-tracking-id="link_select_tool_select"
              data-tracking-value="incentives"
            >
              {makeName} tax credits, rebates and incentives
            </ArrowLink>
          </div>
          <hr className="my-1" />
          <FiltersList activeFilter={activeFilter} submodelsList={submodelsList} setActiveFilter={setActiveFilter} />
          <ModelsList activeFilter={activeFilter} submodelsList={newUsedList} />
          {hasUsedInvLink && (
            <ArrowLink
              to={generateSrpLinkUsedMake(makeSlug)}
              className="mb-1 mt-0_5"
              data-tracking-id="view_search_result"
              data-test="used-inventory-link"
            >
              Shop used {makeName} inventory
            </ArrowLink>
          )}
        </Fragment>
      )}
      <ModelsList activeFilter={activeFilter} submodelsList={preProdList} heading="Upcoming models" />
    </Fragment>
  );
}

ModelsViewUI.propTypes = {
  makeSlug: PropTypes.string.isRequired,
  makeName: PropTypes.string.isRequired,
  submodelsList: PropTypes.arrayOf(CoreResearchEntities.VehicleSubmodel),
  pubStates: PropTypes.shape({}),
  newInventoryCount: PropTypes.number,
};

ModelsViewUI.defaultProps = {
  submodelsList: null,
  pubStates: {},
  newInventoryCount: 0,
};

export const ModelsView = connectToModel(ModelsViewUI, {
  // Inventory filters for new make inventory are set in make-definition.jsx
  newInventoryCount: bindToPath('searchResults', InventoryModel, searchResults =>
    get(searchResults, 'inventories.totalNumber', 0)
  ),
});

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { SOURCES, isDamSrc } from 'client/utils/image-helpers';
import { Image } from 'client/site-modules/shared/components/image/image';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { Container } from 'reactstrap';

import './hero-image.scss';

function HeroImageUI({ heroImage, title, subtitle, isMobile }) {
  return heroImage ? (
    <div className="hero-image">
      <AspectRatioContainer aspectRatio={isMobile ? '16:7' : '18:5'}>
        {isDamSrc(heroImage.href) ? (
          <Image
            imageId={heroImage.href}
            source={SOURCES.DAM}
            alt={heroImage.title}
            className="w-100"
            width={isMobile ? 500 : 1600}
          />
        ) : (
          <Image imageId={heroImage.href} source={SOURCES.MEDIA} alt={heroImage.title} className="w-100" />
        )}
      </AspectRatioContainer>
      {subtitle ? (
        <div
          className={classnames(
            'title-section h-100 mb-0 d-flex flex-column justify-content-center pos-a',
            isMobile ? 'container-fluid w-100' : 'container'
          )}
        >
          <h1 className="display-1 fw-bold text-white with-shadow">{title}</h1>
          <span className="display-2 mt-1_5 text-white with-shadow">{subtitle}</span>
        </div>
      ) : (
        <h1 className="hero-title text-white title-section make-only pos-a d-flex mb-0 w-100">
          <Container className="pb-0_75 mt-auto">
            <span>{title}</span>
          </Container>
        </h1>
      )}
    </div>
  ) : null;
}

HeroImageUI.propTypes = {
  heroImage: PropTypes.shape({
    href: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isMobile: PropTypes.bool,
};

HeroImageUI.defaultProps = {
  heroImage: null,
  title: '',
  subtitle: '',
  isMobile: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const HeroImage = connect(mapStateToProps)(HeroImageUI);

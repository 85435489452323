import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { separateFirstParagraph, separateByFirstCountOfSentences } from 'site-modules/shared/utils/string';
import { SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { Element } from 'react-scroll'; // eslint-disable-line no-restricted-syntax
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { getMakeHistoryContentPath } from 'site-modules/make/utils/make';

import './make-history.scss';

const HISTORY_LENGTH_WITHOUT_COLLAPSE = 2000;

export function MakeHistoryUI({ makeHistoryContent, hasCollapse }) {
  if (!makeHistoryContent) {
    return null;
  }

  const fullHistory = makeHistoryContent.metadata('content').value();
  const [firstParagraphHistory, restHistory] = separateFirstParagraph(fullHistory);
  const [firstTwoSentences, restSentences] = separateByFirstCountOfSentences(firstParagraphHistory);

  return (
    <div className="make-history text-gray-darker">
      {hasCollapse && fullHistory.length > HISTORY_LENGTH_WITHOUT_COLLAPSE ? (
        <Fragment>
          <Element id="scrollTop-make-history" />
          <ContentFragment componentToUse="p">{firstTwoSentences}</ContentFragment>
          <ContentCollapse
            btnContainerClasses="py-0 px-0_5"
            btnClasses="p-0 text-primary-darker read-more-btn"
            btnSize="lg"
            btnArrowClasses="xsmall"
            btnTrackingId="make_index_history"
            justify="justify-content-start"
            textClosed="Read more"
            textOpen="Read less"
            showBtnBelow
            resetParams={{
              duration: SCROLL_DURATION,
              to: 'scrollTop-make-history',
            }}
          >
            {!!restSentences.length && <ContentFragment componentToUse="p">{restSentences}</ContentFragment>}
            <ContentFragment>{restHistory}</ContentFragment>
          </ContentCollapse>
        </Fragment>
      ) : (
        <ContentFragment>{fullHistory}</ContentFragment>
      )}
    </div>
  );
}

MakeHistoryUI.propTypes = {
  makeHistoryContent: CmsEntities.Content,
  hasCollapse: PropTypes.bool,
};

MakeHistoryUI.defaultProps = {
  makeHistoryContent: null,
  hasCollapse: true,
};

const stateToPropsConfig = {
  makeHistoryContent: bindToContent(({ makeSlug }) => getMakeHistoryContentPath(makeSlug)),
};

export const MakeHistory = connectToModel(MakeHistoryUI, stateToPropsConfig);

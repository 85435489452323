import React from 'react';
import { get, head } from 'lodash';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { updateVehicle } from 'client/actions/vehicle';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { VehicleModel } from 'client/data/models/vehicle-v2';
import { buildSubmodelsListPath, CoreResearchModel } from 'client/data/models/core-research';
import { getPageLinksPath, LinkWidgetsModel } from 'client/data/models/link-widgets';
import { PageModel } from 'client/data/models/page';
import { CmsModel } from 'client/data/models/cms';
import { EditorialModel, buildMakeArticlesPath } from 'client/data/models/editorial';
import { InventoryModel } from 'client/data/models/inventory';
import { getModelState } from 'client/data/luckdragon/model';
import { TOP_MAKES_PATH } from 'site-modules/shared/constants/research';
import { CAR_NEWS_LANDING_CONTENT_PATH } from 'client/site-modules/shared/constants/editorial/editorial';
import { getMakeHistoryContentPath, buildCoreLinkFromSubmodel, getMakeBreadcrumbs } from 'site-modules/make/utils/make';
import { getListItemSchema } from 'client/seo/schemas/list';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { parseContent } from 'client/data/cms/content';
import { setCriticalCss } from 'client/actions/critical-css';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import { Make } from './make';

export const pageDefinitionConfig = ({ make }) => ({
  name: 'make_index',
  category: 'make_index',
  preload: store => {
    store.dispatch(updateVehicle({ make: { niceName: make } }));

    const preloader = new ModelPreloader(store);

    preloader.resolve('makes', VehicleModel);
    preloader.resolve(buildSubmodelsListPath(make), CoreResearchModel);
    preloader.resolve(`content["${TOP_MAKES_PATH}"]`, CmsModel);
    preloader.resolve(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel);
    preloader.resolve(`content["${getMakeHistoryContentPath(make)}"]`, CmsModel);
    preloader.resolve(buildMakeArticlesPath(make), EditorialModel);
    preloader.resolve(getPageLinksPath(), LinkWidgetsModel);
    // Get number of new inventories so we can hide the link to new make SRP if there're none of those
    preloader.set('searchResultsFilter', InventoryModel, { make: [make], inventoryType: ['new'] });

    return preloader.load().then(modelState => {
      const historyContent = modelState.get(`content["${getMakeHistoryContentPath(make)}"]`, CmsModel);
      const parsedHistoryContent = parseContent(historyContent);

      const heroImage = head(parsedHistoryContent.child('hero-content').links());
      const hasHeroImage =
        !!(heroImage && heroImage.href) &&
        ExperimentUtil.getForcedOrAssignedRecipeName({
          state: store.getState(),
          campaignName: 'ads-7825',
          defaultVal: 'ctrl',
        }) === 'ctrl';

      if (hasHeroImage) {
        const preloadHeroImageAsset = {
          href: heroImage.href,
          as: 'image',
        };

        preloader.set('server.preloadedAssets', PageModel, [preloadHeroImageAsset]);
      }

      store.dispatch(setCriticalCss({ hasHeroImage }));

      preloader.resolve('searchResults', InventoryModel);

      return preloader.load();
    });
  },
  component: props => <Make makeSlug={make} {...props} />,
  seo: state => {
    const modelState = getModelState(state);
    const makeName = get(modelState.get('makes', VehicleModel), `${make}.name`);
    const submodels = modelState.get(buildSubmodelsListPath(make), CoreResearchModel);
    const title = `${makeName} Vehicles, Trucks and SUVs: Reviews, Pricing, and Specs`;

    return {
      title: `${title} | Edmunds`,
      description: `Research ${makeName} before buying or leasing with expert ratings, in-depth reviews, model comparisons, and more. Get ${makeName} listings, pricing & dealer quotes.`,
      jsonld: [
        getListItemSchema({
          name: title,
          items: submodels.map((submodel, ind) => ({
            name: `${submodel.year} ${makeName} ${submodel.submodelName}`,
            position: ind + 1,
            url: `https://www.edmunds.com${buildCoreLinkFromSubmodel(submodel)}`,
          })),
        }),
        getBreadcrumbSchema(getMakeBreadcrumbs(makeName, true)),
      ],
    };
  },
  options: {
    hasVehicleContext: true,
  },
});

const pages = new Map();

export default params => {
  const { make } = params;
  let page = pages.get(make);
  if (!page) {
    page = pageDefinition(pageDefinitionConfig(params));
    pages.set(make, page);
  }
  return page;
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

import './link-widget-section-list.scss';

/**
 * One link item.
 * @param {Object} item
 * @param {Boolean} [showSentence=false]
 * @return {ReactElement}
 */
export function LinkBlockItem({ item, showSentence, appendPageLinksId }) {
  const { href, anchorText, title, contextualSentence } = item;
  const showContextualSentence = showSentence && !!contextualSentence;
  return href && anchorText ? (
    <li>
      <Link to={href} className="link-block-item size-16" {...appendPageLinksId && { 'edm-smartlink': '001' }}>
        {anchorText || title}
      </Link>
      {showContextualSentence && <div className="xsmall">{contextualSentence}</div>}
    </li>
  ) : null;
}

LinkBlockItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  showSentence: PropTypes.bool,
  appendPageLinksId: PropTypes.bool,
};

LinkBlockItem.defaultProps = {
  showSentence: false,
  appendPageLinksId: false,
};

/**
 * Container for link widget.
 * @param {Object} widgetData
 * @param {Boolean} [showTitle=true]
 * @param {Boolean} [showSentence=false]
 * @return {ReactElement}
 */
export function LinkWidgetSectionList({
  widgetData,
  showTitle,
  showSentence,
  linksNumToShow,
  multicolHeading,
  appendPageLinksId,
  className,
}) {
  const { links = [], header = '' } = widgetData;

  if (!links.length) return null;

  const reducedLinksList = links.slice(0, linksNumToShow || links.length);

  return (
    <Fragment>
      {showTitle && !!header && (
        <h3
          className={classNames('category-heading heading-5 mb-0', {
            multicol: multicolHeading,
          })}
          style={{ breakAfter: 'avoid' }}
        >
          {header}
        </h3>
      )}
      <ul className={classNames('link-widget-section list-unstyled text-cool-gray-10', className)}>
        {reducedLinksList.map((item, index) => {
          const key = `${index}-${item.href}`;
          return (
            <LinkBlockItem item={item} showSentence={showSentence} key={key} appendPageLinksId={appendPageLinksId} />
          );
        })}
      </ul>
    </Fragment>
  );
}

LinkWidgetSectionList.propTypes = {
  widgetData: PropTypes.shape({}),
  showTitle: PropTypes.bool,
  showSentence: PropTypes.bool,
  linksNumToShow: PropTypes.number,
  multicolHeading: PropTypes.bool,
  appendPageLinksId: PropTypes.bool,
  className: PropTypes.string,
};

LinkWidgetSectionList.defaultProps = {
  widgetData: {},
  showTitle: true,
  showSentence: false,
  linksNumToShow: null,
  multicolHeading: true,
  appendPageLinksId: false,
  className: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

export function TopMakesLink({ topMake: { linkUrl, title: makeName }, className, dataTrackingId }) {
  return (
    <p className={classnames('top-make-link-container col-6 col-lg-6 col-md-3 mb-1', className)}>
      <Link to={linkUrl} data-tracking-id={dataTrackingId}>
        {makeName}
      </Link>
    </p>
  );
}

TopMakesLink.propTypes = {
  topMake: PropTypes.shape({
    linkUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  dataTrackingId: PropTypes.string,
};

TopMakesLink.defaultProps = {
  className: null,
  dataTrackingId: null,
};

import { get } from 'lodash';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';

export const SHOP_NEW_CARS_CATEGORY = 'shop-new-cars';

const BEST_TYPE_LEASE_DEALS = [
  {
    href: '/best-truck-lease-deals/',
    title: 'Best Truck Lease Deals',
  },
  {
    href: '/best-suv-lease-deals/',
    title: 'Best SUV Lease Deals',
  },
];

const SHOP_NEW_CARS_TITLE_MAP = {
  'srp-used-make-model': vehicle => `${vehicle.make.name} ${vehicle.model.name}`,
  'srp-used-make': vehicle => vehicle.make.name,
  'srp-used-type': vehicle => vehicle.type,
};

const getShopNewCarsTitle = ({ pageName, vehicle }) =>
  `Shop for a new ${SHOP_NEW_CARS_TITLE_MAP[pageName](vehicle)} for sale here`;

const SHOP_NEW_CARS_HREF_MAP = {
  'srp-used-make-model': vehicle => `${vehicle.make.slug}-${vehicle.model.slug}`,
  'srp-used-make': vehicle => vehicle.make.slug,
  'srp-used-type': vehicle => makeNiceName(vehicle.type),
};

const getShopNewCarsHref = ({ pageName, vehicle }) => `/new-${SHOP_NEW_CARS_HREF_MAP[pageName](vehicle)}-for-sale/`;

const isSomeValueFalsy = values => values.some(val => !!val === false);

export const getStaticLinks = ({ pageName, vehicle }) => {
  switch (pageName) {
    case 'srp-make-lease-deals':
    case 'make-lease-deals':
    case 'srp-make-model-lease-deals':
      return {
        categoryName: 'best-type-lease-deals',
        links: BEST_TYPE_LEASE_DEALS,
      };
    case 'srp-used-make-model':
    case 'srp-used-type':
    case 'srp-used-make':
      return vehicle
        ? {
            categoryName: SHOP_NEW_CARS_CATEGORY,
            links: [
              {
                href: getShopNewCarsHref({ pageName, vehicle }),
                title: getShopNewCarsTitle({ pageName, vehicle }),
              },
            ],
          }
        : null;
    default:
      return null;
  }
};

export const getMakeModelInvLinks = ({ vehicle, inventory = [] }) => {
  const makeName = get(vehicle, 'make.name', '');
  const makeSlug = get(vehicle, 'make.slug', '');
  const modelName = get(vehicle, 'model.name', '');
  const modelSlug = get(vehicle, 'model.slug', '');

  if (!makeName || !modelName) return null;

  const links = [];

  let firstLinkYear;
  let thirdLinkCity;

  // We use "some" here to stop iteration when we have all required links
  inventory.some(vin => {
    const year = get(vin, 'vehicleInfo.styleInfo.year', '');
    const city = get(vin, 'dealerInfo.address.city', '');
    const stateCode = get(vin, 'dealerInfo.address.stateCode', '');

    switch (links.length) {
      case 0:
        if (isSomeValueFalsy([makeSlug, modelSlug, year])) return false;
        firstLinkYear = year;
        links.push({
          anchorText: `${year} ${makeName} ${modelName}`,
          href: `/${makeSlug}/${modelSlug}/${year}/`,
        });
        return false;
      case 1: {
        if (isSomeValueFalsy([makeSlug, modelSlug, year])) return false;
        if (year === firstLinkYear) return false;
        links.push({
          anchorText: `${year} ${makeName} ${modelName}`,
          href: `/${makeSlug}/${modelSlug}/${year}/`,
        });
        return false;
      }
      case 2: {
        if (isSomeValueFalsy([makeSlug, modelSlug, city, stateCode])) return false;
        thirdLinkCity = city;
        links.push({
          anchorText: `Used ${makeName} ${modelName} ${city} ${stateCode}`,
          href: `/used-${makeSlug}-${modelSlug}-${makeNiceName(city)}-${makeNiceName(stateCode)}/`,
        });
        return false;
      }
      case 3: {
        if (isSomeValueFalsy([makeSlug, modelSlug, city, stateCode])) return false;
        if (city === thirdLinkCity) return false;
        links.push({
          anchorText: `Used ${makeName} ${modelName} ${city} ${stateCode} for sale`,
          href: `/used-${makeSlug}-${modelSlug}-${makeNiceName(city)}-${makeNiceName(stateCode)}/`,
        });
        return false;
      }
      default:
        return true;
    }
  });

  return {
    header: `${makeName} ${modelName} Cars for Sale`,
    links,
  };
};

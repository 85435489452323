import { pickBy } from 'lodash';
import { objectToQueryString } from 'site-modules/shared/utils/string';

/**
 * @param {String} make
 * @returns {String} - Make car incentives Url
 * */
export function getMakeIncentivesLink(make) {
  return `/${make}/car-incentives.html`;
}

/**
 * @param {String} make
 * @param {String} model
 * @param {Number} year
 * @param {Number=} id
 * @param {String} defaultSection
 * @returns {String} - Model car incentives Url
 * */
export function getModelIncentivesLink(make, model, year, id, defaultSection = '') {
  const cleanParams = pickBy({ defaultStyleId: id, defaultSection }, value => !!value);
  const queryParams = objectToQueryString(cleanParams);

  return `/${make}/${model}/${year}/deals${queryParams.length ? '?' : ''}${queryParams}`;
}
